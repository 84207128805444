import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'

import { mediaQueries } from '../theme'
import { H1 } from '../components/shared/type'
import Container from '../components/shared/container'
import BaseCategory from '../components/resources/category'
import FooterCta from '../components/footer-cta'
import Layout from '../components/layout'
import PageMetadata from '../components/page-metadata'

const InnerContainer = styled(Container)`
  margin-bottom: ${p => p.theme.space[18]};
  padding: 0;
  ${mediaQueries.md} {
    margin-bottom: ${p => p.theme.space[29]};
  }
`

const Title = styled(H1)`
  padding: 0 ${p => p.theme.space[6]};
  margin-bottom: ${p => p.theme.space[23]};
  ${mediaQueries.lg} {
    padding: 0;
  }
`

const Category = styled(BaseCategory)`
  & + & {
    margin-top: ${p => p.theme.space[12]};
  }
  ${mediaQueries.lg} {
    & + & {
      margin-top: ${p => p.theme.space[23]};
    }
  }
`

const BlogPage = ({ data, location }) => {
  const {
    allMdx: { categories },
  } = data

  return (
    <Layout location={location}>
      <PageMetadata
        title="Getform Blog: Inspiration for Creators Growing Business Online"
        description="If you're a beginning business owner, a freelancer, or a creator paving your way to success online, head over to our blog to learn more about lead generation."
        keywords="getform blog"
      />
      <InnerContainer>
        <Title>Blog</Title>
        {categories.map(c => (
          <Category key={c.slug} posts={c.posts} />
        ))}
      </InnerContainer>
      <FooterCta />
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { resource: { eq: "Blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      categories: group(field: fields___categorySlug) {
        slug: fieldValue
        posts: nodes {
          ...postPreviewFragment
        }
      }
    }
  }
`
